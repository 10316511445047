$(document).ready(function () {
  var pageData;

  var $agencyDetailContainer = $('.js-agency-detail');
  var $primaryIataCodesContainer = $('.js-primary-iata-codes');
  var $administratorDetailContainer = $('.js-administrator-detail');
  var $checkboxIataTids = $('.js-check-iata-tids');

  // Containers
  var $iataTidsListContainer = $('.js-iata-tids-list');
  var $containerTicketsIssued = $('.js-container-tickets-issued');
  var $reviewListContainer = $('.js-review-list');
  var $ticketTidsListContainer = $('.js-iata-tids-ticket-list');
  var $primaryAgentTypeSelection = $('input:radio[name="stateIataNumber"]');
  var $stepRegisterContainer = $('.js-steps-registration-container');
  var $containerAgencyType = $('.js-agency-type-container');

  // Elements
  var iataTidsNumber = '.js-iata-tids-number';
  var $postFormRegister = $('form[name="formAccountRegistration"]');
  var $inputTicket = $('input[name="iataTicket"]');
  var $inputRadio = $('input[name="selectResident"]');

  var $iataTidsAdd = $('.js-iata-tids-add');
  var $ticketsTidsAdd = $('.js-tickets-tids-add');
  var $ticketsTidsAddText = $('.js-tickets-tids-add-text');
  var $emailLogin = $stepRegisterContainer.find('.js-email');

  // Item List states
  var currentAdditionalIataItemIndex = 0;
  var currentTicketIataItemIndex = 0;

  // Handlebars templates
  var $iataTidsItemTemplate = $('#iataTidsItemTemplate');
  var $reviewListTemplate = $('#reviewListTemplate');

  // Compiled Handlebars templates
  var iataTidsItemTpl = Handlebars.compile($iataTidsItemTemplate.html());

  // check show data iata/tids step 3
  $emailLogin.on('change', function () {
    var valueEmail = $(this).val();

    window.APICaller.checkEmailValidity(valueEmail).then(function () {
      $emailLogin.setCustomValidity('');
      $emailLogin.trigger('resetvalidation');

    }, function (error) {
      $emailLogin.setCustomValidity(error);
      $emailLogin.trigger('updatevalidation');
    });
  });

  $checkboxIataTids.on('change', function () {
    const $this = $(this);
    const $container = $this.parents('.js-primary-iata-codes');
    const $iataAgent = $container.find('.js-iata-agent');
    const $iataPrimaryRelated = $container.find('.js-iata-primary-related');
    const $companyLegalRegister = $container.find('input[name="companyLegalRegister"]');
    const $agentDetailsCountry = $container.find('.js-agent-details-country');
    const $agentDetailState = $container.find('.js-agent-details-state');
    const $agentDetailCity = $container.find('.js-agent-details-city');

    clearDataInput();

    if ($this.is(':checked')) {
      $iataAgent.attr('disabled', true);
      $agentDetailState.attr('disabled', true);
      $agentDetailCity.attr('disabled', true);
      $companyLegalRegister.prop('disabled', false);
      $agentDetailsCountry.prop('disabled', false);
    } else {
      $iataAgent.prop('disabled', false);
      $iataPrimaryRelated.attr('disabled', true);
    }
  });

  function checkValidateCheckAgencyType() {
    let boxTicket = 0;

    $('.js-checkbox-handler-agency').each(function (idx, element) {
      const $element = $(element);

      if ($element.is(':checked')) {
        boxTicket = boxTicket + 1;
      }
    });

    if (boxTicket === 0) {
      $('.js-checkbox-error').removeClass('hidden');
    } else {
      $('.js-checkbox-error').addClass('hidden');
    }

    return boxTicket;
  }

  $(document).on('click', '.js-checkbox-handler-agency', checkValidateCheckAgencyType);

  function checkRadioTicket() {
    const $this = $(this);
    $inputTicket.removeAttr('checked');

    $this.attr('checked', true);
    $this.parent().find('.form__radio-group').removeClass('border-check-box');

    if ($('.js-check-ticket-center').is(':checked')) {
      $containerTicketsIssued.removeClass('hidden');
      //Reset currentTicketIataItemIndex
      currentTicketIataItemIndex = 0;

      if ($containerTicketsIssued.find('.js-iata-tids-number').length > 0) {
        return;
      }

      $ticketsTidsAdd.trigger('click');
      $containerTicketsIssued.find('.js-remove-item').hide();
    }

    if ($('.js-check-own-ticket').is(':checked')) {
      $ticketTidsListContainer.html('');
      $containerTicketsIssued.addClass('hidden');

      window.validateInvalidDuplicatedFields();
    }
  }

  $inputTicket.on('change', checkRadioTicket);

  $inputRadio.on('click', function () {
    $inputRadio.removeAttr('checked');
    $(this).attr('checked', true);
  });

  $iataTidsAdd.on('click', function () {
    const $primaryAgentTypeOption = $primaryAgentTypeSelection.filter(':checked');
    const isPrimaryAgentTypeIATA = $primaryAgentTypeOption.val() === 'iata';
    const $renderedElement = renderAppendMode(iataTidsItemTpl, $iataTidsListContainer, {
      isIataagentType: true,
      hideAgentTypeSelection: !isPrimaryAgentTypeIATA,
      elmIndex: currentAdditionalIataItemIndex,
    });

    var $removeButton = $renderedElement.find('.js-remove-item');
    $removeButton.on('click', function () {
      const $removingElm = $(this).closest(iataTidsNumber);
      $removingElm.remove();
      window.validateInvalidDuplicatedFields();
    });

    window.initializeIATAInput($renderedElement);

    if (isPrimaryAgentTypeIATA) {
      $(`input:radio[name="stateAgentIataTids-${currentAdditionalIataItemIndex}"]`).on('change', function () {
        const $this = $(this);
        const $container = $this.closest(iataTidsNumber);
        const $iataAgentInput = $container.find('.js-iata-agent');
        window.updateDataField($iataAgentInput, '');
        $iataAgentInput.trigger('clearRelatedFields');
      });
    }
    currentAdditionalIataItemIndex++;
  });

  $ticketsTidsAdd.on('click', function () {
    const $renderedElement = renderAppendMode(iataTidsItemTpl, $ticketTidsListContainer, {
      isIataagentType: true,
      hideAgentTypeSelection: true,
      elmIndex: currentTicketIataItemIndex,
      indexTitle: currentTicketIataItemIndex + 1,
      forTicket: true
    });

    $containerTicketsIssued.find('.js-remove-item').show();

    const $removeButton = $renderedElement.find('.js-remove-item');
    $removeButton.on('click', function () {
      const $removingElm = $(this).closest(iataTidsNumber);
      $removingElm.remove();

      // Reset ticket index
      const $ticketIndex = $('.js-ticket-index');
      const numberTicket = $ticketIndex.length;

      $ticketIndex.each(function (index, item) {

        if (index === 0 && numberTicket === 1) {
          $(item).parents('.js-iata-tids-number').find('.js-remove-item').hide();
        }

        $(item).html(index + 1);
      });

      // Reset currentTicketIataItemIndex
      currentTicketIataItemIndex = $ticketIndex.length;

      const iataCodeCount = $ticketTidsListContainer.find(iataTidsNumber).length;

      if (iataCodeCount === 0) {
        $ticketsTidsAddText.html('Add IATA code');
      }

      window.validateInvalidDuplicatedFields();
    });

    window.initializeIATAInput($renderedElement);

    currentTicketIataItemIndex++;

    $ticketsTidsAddText.html('Add another');
  });

  $(document).on('change', '.js-iata-tids-radio', function () {
    var parent = $(this).closest('.js-iata-tids-number');

    if (this.checked && this.value === 'iata') {
      $(parent).find('input[name=iataAgentAdditional] + .js-float-label').text('IATA / ARC code');
    } else {
      $(parent).find('input[name=iataAgentAdditional] + .js-float-label').text('TIDS code');
    }
  });

  function clearDataInput() {
    const $inputOnChange = $primaryIataCodesContainer.find('.js-input-on-change');

    $inputOnChange.val('');
    $inputOnChange.removeClass('input-marked-modify');
    $primaryIataCodesContainer.find('.js-float-label').removeClass('floating-label-modify');
    $inputOnChange.setCustomValidity('');
    $inputOnChange.trigger('resetvalidation');
  }

  function renderAppendMode(hdbTemplate, $containerList, data) {
    var $htmlCompiled = $(hdbTemplate({data}));
    $containerList.append($htmlCompiled);
    return $htmlCompiled;
  }

  // handle steps
  const isShown = 'is-shown';
  const isActive = 'is-active';

  const $stepsList = $('.js-steps-list');
  const $stepsItem = $stepsList.find('li');
  const $currentTab = $stepsList.find('input[type="hidden"]');
  const $tabContent = $('.js-tab-content');
  const $stepsButton = $tabContent.find('.js-steps-button');
  const $backButton = $('.js-back-link');

  function handleActiveStep($this) {
    const index = $this.index();
    const tabId = $this.data('current-tab');

    populateDataReview();

    const $targetSelector = $(tabId);

    $stepsItem.removeClass(isActive);
    for (let i = 0; i <= index; i++) {
      const step = $stepsItem[i];
      $(step).addClass(isActive);
    }

    $tabContent.removeClass(isShown);
    $targetSelector.addClass(isShown);

    $currentTab.val(tabId);

    // handle email
    const $emailToLogin = $tabContent.find('input[name="emailToLogin"]');
    const $email = $tabContent.find('input[name="email"]');

    window.markFloatingLabel($email, !!$emailToLogin.val());

    $email.val($emailToLogin.val());

    // check validate agency type

  }

  function checkValidate($this) {
    const $form = $this.closest('.js-tab-content');

    const $requiredEls = $($form).find('*[required]:visible');
    const validateAgencyType = checkValidateCheckAgencyType();

    let invalid = false;

    for (let i = 0; i < $requiredEls.length; i++) {
      const el = $requiredEls[i];
      if (!$(el).callProp('checkValidity')) {
        invalid = true;
      }
    }

    return !invalid && validateAgencyType;
  }

  // step 3 review
  function getDataListIataTids($container, $listItem) {
    var iataNumbers = [];

    $container.find($listItem).each(function (idx, element) {
      var $element = $(element);
      var index = idx + 1;
      var agentType = getAgentTypeFromValue($element.find('input[type="radio"]:checked').val());
      var codeType = $element.find('input[name="additionalCodeType"]').val();

      iataNumbers.push({
        index: `${index}.`,
        agentType: agentType,
        agentTypeName: getAgentTypeName(agentType),
        codeName: getCodeName(codeType),
        iataNumber: $element.find('.js-iata-agent').val(),
        companyLegal: $element.find('input[name="companyLegalAdditional"]').val(),
        country: $element.find('input[name="countryAdditional"]').val(),
      });
    });

    return iataNumbers;
  }

  function getDataListAgencyType($container, $listItem) {
    var agencyTypes = [];

    $container.find($listItem).each(function (idx, element) {
      var $element = $(element);
      if (!$element.is(':checked')) {
        return;
      }
      var labelText = $element.parent().text();

      agencyTypes.push(labelText);
    });

    return agencyTypes;
  }


  function populateDataReview() {
    var administratorPhoneCountryCode = $administratorDetailContainer.find('input[name="phoneCode"]').val();
    var administratorPhoneAreaCode = $administratorDetailContainer.find('input[name="phoneCountry"]').val();
    var administratorPhonePhoneNumber = $administratorDetailContainer.find('input[name="phoneNumber"]').val();

    var agentType = getAgentTypeFromValue($agencyDetailContainer.find('input[name=stateIataNumber]:checked').val());
    var codeType = $agencyDetailContainer.find('input[name="codeType"]').val();

    pageData = {
      organization: {
        agentType: agentType,
        agentTypeName: getAgentTypeName(agentType),
        codeName: getCodeName(codeType),
        codeNameForAdditional: getCodeNameForAdditional(agentType),
        itataCode: $agencyDetailContainer.find('input[name="iataAgentPrimary"]').val(),
        companyLegal: $agencyDetailContainer.find('input[name="companyLegalRegister"]').val(),
        listAgencyType: getDataListAgencyType($containerAgencyType, $('.js-checkbox-handler-agency')),
        ticketsIssued: $agencyDetailContainer.find('input[name=iataTicket]:checked').val(),
        ticketsIssuedText: $agencyDetailContainer.find('input[name=iataTicket]:checked').parent().text(),
        listIataTicket: getDataListIataTids($containerTicketsIssued, iataTidsNumber),
        country: $agencyDetailContainer.find('.js-agent-details-country').val(),
        state: $agencyDetailContainer.find('.js-agent-details-state').val(),
        city: $agencyDetailContainer.find('.js-agent-details-city').val()
      },
      administrator: {
        residential: $administratorDetailContainer.find('input[name="selectResident"]:checked').val(),
        email: $administratorDetailContainer.find('input[name="emailToLogin"]').val(),
        firstName: $administratorDetailContainer.find('input[name="firstName"]').val(),
        lastName: $administratorDetailContainer.find('input[name="lastName"]').val(),
        designation: $administratorDetailContainer.find('input[name="designation"]').val(),
        travelAgentID: $administratorDetailContainer.find('input[name="travelAgentId"]').val(),
        iataIatanVerification: $administratorDetailContainer.find('input[name="IATAIATANVerification"]').val(),
        phoneNumber: getDisplayedPhoneNumber(administratorPhoneCountryCode, administratorPhoneAreaCode, administratorPhonePhoneNumber)
      }
    };

    var reviewListTpl = Handlebars.compile($reviewListTemplate.html());
    var htmlReviewCompiled = reviewListTpl({pageData: pageData});
    $reviewListContainer.html(htmlReviewCompiled);
  }

  function getAgentTypeFromValue(agentType) {
    return agentType === 'iata' ? 'iata' : 'non-iata';
  }

  function getAgentTypeName(agentType) {
    return agentType === 'iata' ? 'IATA agent' : 'Non-IATA agent';
  }

  function getCodeName(codeType) {
    if (codeType === '') {
      return '';
    }

    return codeType === 'IATA' ? 'IATA / ARC' : 'TIDS';
  }

  function getCodeNameForAdditional(agentType) {
    return agentType === 'iata' ? 'IATA / TIDS' : 'TIDS';
  }

  function getDisplayedPhoneNumber(agencyPhoneCountryCode, agencyPhoneAreaCode, agencyPhonePhoneNumber) {
    agencyPhoneCountryCode = agencyPhoneCountryCode.replace(/[()A-Za-z ]/g, '');

    return agencyPhoneCountryCode + (agencyPhoneAreaCode ? (' ' + agencyPhoneAreaCode) : '') + ' ' + agencyPhonePhoneNumber;
  }

  // List

  $stepsItem.on('click', function () {
    const $this = $(this);
    const $current = $($currentTab.val());

    let validate = checkValidate($current);
    if (!validate) {
      return;
    }

    handleActiveStep($this);
  });

  $stepsButton.on('click', function () {
    const $this = $(this);

    let validate = checkValidate($this);
    if (!validate) {
      return;
    }

    const nextTabId = $this.data('next-tab');

    if (!nextTabId) {
      return;
    }

    const $nextStep = $stepsList.find('li[data-current-tab="' + nextTabId + '"]');

    handleActiveStep($nextStep);
  });

  $backButton.on('click', function () {
    const $this = $(this);

    const prevTabId = $this.data('prev-tab');

    if (!prevTabId) {
      return;
    }

    const $prevStep = $stepsList.find('li[data-current-tab="' + prevTabId + '"]');

    handleActiveStep($prevStep);
  });


  // handle form submit
  var $termCondition = $('.js-term');
  var $verifyEmail = $('.js-verify-email');
  var $verifyEmailText = $('.js-verify-email-text');
  var $resendEmailCounter = $('.js-resend-email-counter');
  var $resendEmailLink = $('.js-resend-verify-email-button');
  var $resendVerifyEmail = $('.js-resend-verify-email');
  var $verifyResendEmail = $('.js-verify-resend-header');

  var counterEmailTemplate = $resendEmailCounter.data('counter-template');
  var countFromEmail = $resendEmailCounter.data('count-from');
  var verifyresendHeader = $verifyResendEmail.data('resend-header');
  var resendEmailText = $verifyEmailText.data('resend-email-text');

  $termCondition.on('change', function () {
    if (this.checked) {
      $('.js-submit-form').addClass(isActive);
    } else {
      $('.js-submit-form').removeClass(isActive);
    }
  });

  $postFormRegister.on('submit', function (event) {
    event.preventDefault();

    var data = {
      agentType: pageData.organization.agentType,
      itataCode: pageData.organization.itataCode,
      companyLegal: pageData.organization.companyLegal,
      agencyType: pageData.organization.agencyType,
      numberEmployees: pageData.organization.numberEmployees,
      ticketsIssued: pageData.organization.ticketsIssued,
      listIataTicket: pageData.organization.listIataTicket,
      country: pageData.organization.country,
      state: pageData.organization.state,
      city: pageData.organization.city,
      postalCode: pageData.organization.postalCode,
      additionalIataTids: pageData.organization.additionalIataTids,
      residential: pageData.administrator.residential,
      email: pageData.administrator.email,
      firstName: pageData.administrator.firstName,
      lastName: pageData.administrator.lastName,
      designation: pageData.administrator.designation,
      travelAgentID: pageData.administrator.travelAgentID,
      phoneNumber: pageData.administrator.phoneNumber,
      password: $stepRegisterContainer.find('.js-validate-new-password').val()
    };

    window.APICaller.accountRegistration(data, window.grecaptchaResponse).then(function (data) {
      showVerifyEmailPage();
    });
  });

  function showVerifyEmailPage() {
    $postFormRegister.addClass('hidden');
    $verifyEmail.removeClass('hidden');
    initializeVerifyCounter();
    $verifyEmailText.html($verifyEmailText.html().replace('{email}', pageData.administrator.email));
  }

  function initializeVerifyCounter() {
    var currentCount = 1;
    $resendEmailCounter.html(counterEmailTemplate.replace('{time}', countFromEmail));

    var interval = setInterval(function () {
      if (currentCount === countFromEmail) {
        currentCount = 0;
        clearInterval(interval);
        showResendVeryLink();
      } else {
        $resendEmailCounter.html(counterEmailTemplate.replace('{time}', countFromEmail - currentCount));
        currentCount += 1;
      }
    }, 1000);
  }

  $resendEmailLink.on('click', function (e) {
    e.preventDefault();

    window.APICaller.resendEmail().then(function () {
      hideResendVeryLink();
      $verifyResendEmail.html(verifyresendHeader);
      $verifyEmailText.html(resendEmailText.replace('{email}', pageData.administrator.email));
      initializeVerifyCounter();
    });
  });

  function hideResendVeryLink() {
    $resendEmailCounter.removeClass('hidden');
    $resendVerifyEmail.addClass('hidden');
  }

  function showResendVeryLink() {
    $resendEmailCounter.addClass('hidden');
    $resendVerifyEmail.removeClass('hidden');
  }
});
